@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


:root {
	--blue-color: #11235A;
}

* {
	font-family: "Poppins", sans-serif !important;
	font-style: normal !important;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	margin: 0;
}

#Layoutroot {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

main {
	flex: 1;
}

body h2 {
	font-size: 3rem;
	font-weight: 600;
	color: #11235A;
	transition: transform 0.3s ease-in-out;
}

body p {
	margin-bottom: 0 !important;
	color: #11235A;
}

h1 {
	/* color: #C20F00 !important; */
	/* font-size: 40px !important; */
}

h2 {
	color: #11235A;
}


body p,
body h1,
body h4 {
	font-weight: 500;
	color: #11235A;
}


.Mainbar img {
	width: 18rem;
}

.Mainbar {
	/* display: flex; */
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-style: normal;
	top: 0%;
	position: sticky;
	z-index: 1000;
	/* padding: 10px; */
}

#uppernav {
	background: #11235A;
	color: #ffffff;
}

#uppernav span {
	font-size: 0.8rem;
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: 0 0 0 #ffffff !important;
}

.navbar-nav {
	font-weight: bold;
	gap: 38px;
	padding: 3rem !important;
}

.navbar-nav .nav-item a {
	color: #11235A;
	transition: transform 0.3s ease-in-out;
}

.navbar-nav .nav-item a:hover {
	color: #C20F00;
	/* text-decoration: underline; */
	text-decoration-color: #C20F00;
	text-decoration-thickness: 2px;
	text-decoration-style: solid;
	transition: .4s;
	transform: scale(1.2);
}

.navbar-nav .nav-link.active {
	text-decoration: underline;
	text-decoration-color: #C20F00;
	text-decoration-thickness: 2px;
	text-decoration-style: solid;
	color: #C20F00 !important;
}


.navcont {
	clip-path: polygon(1.2% 0, 100% 0, 100% 100%, 7% 100%);
}

.banner img {
	transition: clip-path 1s .1s ease-in-out;
	height: 100%;
	width: 100%;
}

.carousel-box {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 2px solid #ffffff6b;
}

.carousel-inner {
	height: 75vh;
}

#carouselExampleCaptions {
	width: 100%;
	position: relative;
	transition: all ease-in-out 1.2s;

}

.carousel-caption {
	top: 35%;
	bottom: auto;
}

.carousel-item>img {
	/* object-fit: contain; */
	transition: all ease-in-out .6s;
}

#carouselExampleCaptions:hover .carousel-item>img {
	transform: scale(1.1);
	overflow: hidden;
}

@media (max-width: 768px) {
	.carousel-inner {
		height: auto !important;
	}

	.carousel-box {
		height: auto !important;
	}

	.carousel-caption {
		top: auto !important;
	}

	.navbar-nav .nav-item a:hover {
		color: inherit;

		text-decoration: none;

		transform: none;

		transition: none;

	}

}

/* end of banner */

/* cards */

.cardBox {}

#card {
	border-radius: 0px !important;
	border: 2px solid #707070;
	position: relative;
	overflow: hidden;
}

#card img {
	width: 100%;
	height: 100% !important;
	transition: transform 0.5s ease-in-out;
}

#card:hover img {
	transform: scale(1.1);
}

#card-overlay {
	position: absolute;
	background: linear-gradient(to bottom, #ffffff1a, #00000079 70%);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.438);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	text-align: center;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

#card:hover #card-overlay {
	opacity: 1;
}






/* cards-end */

/* about */

.checkbtn {
	font-weight: 600;
	color: #11235A;
}

#about img {
	width: 25vw;
}

svg {
	margin: 8px;
}

.successhead {
	position: relative;

}

.successhead:before {
	background: #11235A;
	content: "";
	height: 3px;
	left: 120px;
	position: absolute;
	top: 12px;
	width: 12vw;
}

/* aboutend */


/* counting-numbers */



#countcontain {
	display: flex;
	gap: 20px;
	/* flex-direction: column; */
	justify-content: center;
	/* align-items: center; */
}

#count {
	border: 2px solid #c20f0082;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	transition: transform 0.4s ease-in-out;
}

#count h2 {
	margin-bottom: 24px;
	transition: transform 0.4s ease-in-out;

}

#count p {
	margin-bottom: 10px;
	transition: transform 0.4s ease-in-out;

}

#count:hover {
	background-color: beige;
}

#count:hover p {
	transform: scale(1.1);
	color: #C20F00;

}

#count:hover h2 {
	transform: scale(1.4);
	color: #C20F00;

}

#count h2 {

	font-weight: 600 !important;
}

#count p {

	font-weight: 500 !important;
}


/* end of count */



/* service page */

#topimg {
	position: relative;
	text-align: center;
	overflow: hidden;
	clip-path: ellipse(64% 100% at top);
	box-shadow: 0 10px 15px rgba(236, 118, 118, 0.918);

}


.curved-image {
	/* border: 3px solid #11235A; */
	width: 100%;
	height: 26rem;
	object-fit: cover;
}


.overlay {
	position: absolute;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 3.5rem;
	font-weight: 600;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

#row {
	text-align: -webkit-center;
	width: 74%;

}



#animated1:hover svg path {
	stroke-dasharray: 68;
	stroke-dashoffset: 64;
	animation: animate 2s ease-in-out;
}

@keyframes animate {

	from {
		stroke-dashoffset: 68;
	}

	to {
		stroke-dashoffset: 0;
	}
}

#icons {
	padding: 12px;
	width: 140px;
	height: 132px;
	border: 4px solid red;
	/* border-radius: 43px; */
}


/* service ends */
/* about section */



#aboutsideimg img {
	border-radius: 150px 150px 0px 0px;
	border: 4px solid #C20F00;
	width: 100%;
	object-fit: cover;
	height: 100%;
}

#fliping {
	transform: rotateY('180deg');
}

/* about section end */


/* Footer section  */

.fotter {
	border: 3px solid #11235A;
}

.footer-icons {
	margin-top: 25px;
}

.footer ul {
	list-style: none;
	padding-left: 0;
}

.footer ul li i {
	margin-right: 10px;
}

footer a {
	text-decoration: none !important;
	color: #ffffff !important;
}

footer {
	background: rgb(17, 35, 90);
}

footer h5,
li {
	color: #ffffff;
}

#whitefoot {
	background: #ffffff;
	width: 22%;
}

.footer-icons a i {
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	/* background-color: #33383b; */
	border-radius: 2px;

	font-size: 20px;
	color: #11235A;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}

#footertxt {
	display: flex;
	justify-content: center;
}


/* blog page starts here */
#cardcont {
	border-radius: 8px;
	border: 3px solid #11235A;
	overflow: hidden;
}

#cardcontainer {
	justify-content: center;
	align-items: center;
	display: flex;
}

.cardimg {
	/* border-radius: 10px; */
	border-bottom: 3px solid #11235A;
	height: 35vh;
	object-fit: cover;
}

.card-text {
	font-size: larger;
}

hr {
	border: 2px solid #11235A !important;
}

.searchbar {
	background: #33383b;
	width: 100% !important;
	border-radius: 10px;
}

.searchbar input {
	border-radius: 20px;
	border: none;
	/* padding-left: 5px; */
	width: 90%;
	height: 2.5rem;
}

.lists {
	border: 2px solid #11235A;
	border-radius: 3%;
}

#category {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.pagination {
	margin: 0;
	padding: 0;
	list-style: none;
}

.pagination .page-item {
	margin: 0 5px;
}

.pagination .page-link {
	padding: 10px 15px;
	border: 1px solid #ddd;
	border-radius: 5px;
	color: #11235a;
	background-color: #fff;
	transition: all 0.3s ease;
	text-decoration: none;
	cursor: pointer;
}

.pagination .page-link:hover {
	background-color: #11235a;
	color: #fff;
}

.pagination .page-item.active .page-link {
	background-color: #11235a;
	color: #fff;
	border-color: #11235a;
}

.line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}


/* blog-end */

.vid-wrap {
	width: 15rem;
}

@media (max-width:768px) {
	.vid-wrap{
		width: 8rem !important;
	}
}

/* Container for the accordion */
#accordion-start {
	display: grid;
	place-items: center;
	width: 100%;
	padding: 0 15%;
}

/* Ensure the accordion takes the full width */
.accordion {
	width: 100% !important;
}

.accordion-body {
	color: #11235A;
}

/* Default accordion button appearance */
.accordion-button {
	color: #11235A;
	padding: 1rem;
	/* Adjust padding if necessary */
}

/* When the accordion button is focused */
.accordion-button:focus {
	outline: none;
	/* Remove the default outline */
	box-shadow: none !important;
	/* Remove the box-shadow */
}

/* Accordion button plus sign (default) */
.accordion-button::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
	width: 50px !important;
	transition: all 1.5s;
}

/* Change the accordion button icon to minus when expanded */
.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#ffffff' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
}

.CareerPage button {
	background-color: #11235A;
	color: #ffffff;
}

.CareerPage button:hover {
	background-color: #11235ae1;
	color: #ffffff !important;
}

  

/* responsive footer & header */

@media screen and (max-width: 768px) {

	#card-overlay {
		opacity: 1 !important;
	}

	.accordion {
		padding: 0 !important;
	}

	#accordion-start {
		padding: 0 !important;
	}

	h1 {
		font-size: larger !important;
	}

	h2 {
		font-size: x-large !important;
	}



	p {
		font-size: smaller;
	}

	#uppernav {
		display: none !important;
	}

	.Mainbar {
		/* display: block; */
	}

	.Mainbar img {
		width: 13rem !important;
	}

	.navbar-nav {
		gap: 0px;
		padding: .25rem !important;
	}

	.banner img {
		height: auto;
	}

	#card {
		height: auto;
	}

	#card img {
		height: auto;
	}

	.cardimg {
		/* border-radius: 10px; */
		border-bottom: 3px solid #11235A;
		height: 26vh;
		object-fit: cover;
	}

	#about img {
		width: 100%;
	}

	.successhead:before {
		width: 32vw;
	}

	#counter {
		width: 60%;
	}

	#countcontain {
		/* padding-left: 8rem;
		padding-right: 8rem; */
	}

	#count h2 {
		font-size: 20px !important;
	}

	#count p {
		font-size: 15px !important;
	}

	#whitefoot img {
		width: 5rem;
	}

	#whitefoot {
		width: 100%;
	}

	#footertxt {
		display: block;
	}

	.curved-image {
		height: 10rem;
	}

	.overlay {
		font-size: 2rem;
	}

	#abouth1 {
		padding: 0% !important;
	}

	#aboutsideimg {
		/* border: 3px solid #C20F00; */
		margin-bottom: 2rem;

	}

	#aboutsideimg div h5 {
		/* border: 3px solid #C20F00; */
		/* margin-bottom: 1rem !important; */

	}
}


/* -----------------------------------------  contact page --------------------------------------- */


.contact-form-wrap {
	/* max-width: 1200px; */
	margin: 0 auto;
	padding: 20px;
}

h1 {
	/* text-align: center;
    margin-bottom: 20px; */
}

.contact-form {
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form label {
	display: block;
	margin-bottom: 10px;
	font-weight: bold;
}

.contact-form input,
.contact-form textarea {
	width: 100%;
	padding: 10px;
	margin-bottom: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 16px;
}

.contact-form textarea {
	resize: vertical;
	min-height: 80px;
}

.contact-form button {
	background-color: #11235A;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
}

.contact-form button:hover {
	background-color: #fff !important;
	color: #11235A !important;
	outline: 1px solid #11235A;
}

.zoomBox {
	overflow: hidden;
	width: 10rem;
}

.zoom {
	transition: all 0.6s ease-in-out;

}

.zoomBox:hover .zoom {
	transform: scale(1.1);
}

@media (max-width: 768px) {
	.contact-form {
		width: auto !important;
		padding: 15px;
	}
}

.contact-formm {
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-formm label {
	display: block;
	margin-bottom: 10px;
	font-weight: bold;
}

.contact-formm input,
.contact-formm textarea {
	width: 100%;
	padding: 10px;
	margin-bottom: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	font-size: 16px;
}

.contact-formm textarea {
	resize: vertical;
	min-height: 150px;
}

.contact-formm button {
	background-color: #11235A;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
}

.contact-formm button:hover {
	background-color: #fff;
	color: #11235A;
	outline: 1px solid #11235A;
}

@media (max-width: 768px) {
	.contact-formm {
		width: auto !important;
		padding: 15px;
	}

	.swiper-pagination {
		display: none !important;
	}

}